<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter header-medium" parallax-active="true" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Cartografia Literaturii Române Vechi
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div>
          <div class="md-layout">
            <div class="md-layout-item mx-auto text-center">
              <template>
                <div class="row">
                  <roMap :mapData="mapData" v-if="!showProgress"></roMap>
                  <div class="middle" v-else>
                    <vue-ellipse-progress loading :size="50" :progress="100" color="green" emptyColor="#a4acb3" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import roMap from "./RoMap";

export default {
  components: {
    roMap
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      showProgress: false,
      mapData: []
    };
  },
  methods: {
    getMapData() {
      this.showProgress = true;
      this.mapData = [];
      this.axios.get(`${process.env.VUE_APP_SERVER_URL}/map`).then(
        response => {
          if (response && response.status == 200) {
            this.mapData = response.data;
            this.showProgress = false;
          }
        }
      );
    }

  },
  async mounted() {
    this.getMapData();
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.pdf-entry {
  font-size: 12px;
}

.full-height {
  height: 100vh;
}
</style>
