<!-- <template>
    <div>
      <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
    </div>
  </template>
  
  <script>


  export default {
    data() {
      return {
        chartOptions: {
        chart: {
          map: "roMap"
        },
          title: {
            text: 'Highmaps basic demo'
          },
          subtitle: {
            text: 'lalalaaaaaa'
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              alignTo: 'spacingBox'
            }
          },
          colorAxis: {
            min: 0
          },
          series: [{
            name: 'Random data',
            states: {
              hover: {
                color: '#BADA55'
              }
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}'
            },
            allAreas: false,
            data: this.getMapData()
          }]
        }
      };
    },
    methods: {
        getMapData() {
            return [
        ['ro-bi', 10], ['ro-cs', 11], ['ro-tm', 12], ['ro-bt', 13],
        ['ro-bn', 14], ['ro-cj', 15], ['ro-ab', 16], ['ro-hd', 17],
        ['ro-mm', 18], ['ro-ms', 19], ['ro-sj', 20], ['ro-sm', 21],
        ['ro-ag', 22], ['ro-sb', 23], ['ro-vl', 24], ['ro-bv', 25],
        ['ro-cv', 26], ['ro-hr', 27], ['ro-is', 28], ['ro-nt', 29],
        ['ro-ph', 30], ['ro-sv', 31], ['ro-bc', 32], ['ro-br', 33],
        ['ro-bz', 34], ['ro-gl', 35], ['ro-vs', 36], ['ro-vn', 37],
        ['ro-if', 38], ['ro-tl', 39], ['ro-dj', 40], ['ro-gj', 41],
        ['ro-mh', 42], ['ro-ot', 43], ['ro-tr', 44], ['ro-cl', 45],
        ['ro-db', 46], ['ro-gr', 47], ['ro-il', 48], ['ro-ct', 49],
        ['ro-ar', 50], ['ro-bh', 51]
    ];
        }
    }
  };
  </script>
   -->

<template>
  <div>
    <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>
  
<script>


export default {
  props: {
    mapData: Array
  },
  data() {
    return {
      computedMapData: [

      ],
      hashMapData: {},
      chartOptions: {
        chart: {
          map: "roMap"
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        mapNavigation: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        colorAxis: {
          min: 0,
          max: 50
        },
        tooltip: {
          formatter: function () {
            // If you want to see what is available in the formatter, you can
            // examine the `this` variable.
            return `<b>${this.point.name}: ${this.point.value}</b>`;
          }
        },
        plotOptions: {
          area: {
            color: '#de9825',
          },
        },
        series: [{
          name: null,
          states: {
            hover: {
              color: '#de9825'
            }
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
          allAreas: true,
          data: this.parseMapData()
        }]
      }
    };
  },
  watch: {
    mapData: function (newVal, oldVal) {
      this.parseMapData();
    }
  },
  mounted() {
    this.parseMapData();
  },
  methods: {
    getMapData() {
      return [
        ['ro-bi', 10], ['ro-cs', 11], ['ro-tm', 12], ['ro-bt', 13],
        ['ro-bn', 14], ['ro-cj', 15], ['ro-ab', 16], ['ro-hd', 17],
        ['ro-mm', 18], ['ro-ms', 19], ['ro-sj', 20], ['ro-sm', 21],
        ['ro-ag', 22], ['ro-sb', 23], ['ro-vl', 24], ['ro-bv', 25],
        ['ro-cv', 26], ['ro-hr', 27], ['ro-is', 28], ['ro-nt', 29],
        ['ro-ph', 30], ['ro-sv', 31], ['ro-bc', 32], ['ro-br', 33],
        ['ro-bz', 34], ['ro-gl', 35], ['ro-vs', 36], ['ro-vn', 37],
        ['ro-if', 38], ['ro-tl', 39], ['ro-dj', 40], ['ro-gj', 41],
        ['ro-mh', 42], ['ro-ot', 43], ['ro-tr', 44], ['ro-cl', 45],
        ['ro-db', 46], ['ro-gr', 47], ['ro-il', 48], ['ro-ct', 49],
        ['ro-ar', 50], ['ro-bh', 51]
      ];
    },
    initializeHashMapData() {
      this.hashMapData = {
        'ro-bi': [], 'ro-cs': [], 'ro-tm': [], 'ro-bt': [],
        'ro-bn': [], 'ro-cj': [], 'ro-ab': [], 'ro-hd': [],
        'ro-mm': [], 'ro-ms': [], 'ro-sj': [], 'ro-sm': [],
        'ro-ag': [], 'ro-sb': [], 'ro-vl': [], 'ro-bv': [],
        'ro-cv': [], 'ro-hr': [], 'ro-is': [], 'ro-nt': [],
        'ro-ph': [], 'ro-sv': [], 'ro-bc': [], 'ro-br': [],
        'ro-bz': [], 'ro-gl': [], 'ro-vs': [], 'ro-vn': [],
        'ro-if': [], 'ro-tl': [], 'ro-dj': [], 'ro-gj': [],
        'ro-mh': [], 'ro-ot': [], 'ro-tr': [], 'ro-cl': [],
        'ro-db': [], 'ro-gr': [], 'ro-il': [], 'ro-ct': [],
        'ro-ar': [], 'ro-bh': [], 'null': []
      }
    },
    parseMapData() {
      this.initializeHashMapData();

      this.computedMapData = [];

      for (let entry of this.mapData) {
        this.hashMapData[entry.countyAbbr].push(entry);
      }

      for (let key in this.hashMapData) {
        this.computedMapData.push([key, this.hashMapData[key].length]);
      }

      return this.computedMapData;

    },
  }
};
</script>
  